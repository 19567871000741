const bootstrap = require("./bootstrap");

// $(function(){ // only for testing here
//     check_darkmode();
// });

// function check_darkmode() { // only for testing here
//     axios.get('/settings/getByName/darkmode').then(function(response){
//         if(response.data == 1 && response.status == 200){
//             $('.sidebar-body, nav.navbar, .sidebar-header').css({
//                 'background-color': '#000000',
//                 'color': '#ffffff'
//             });

//             $('.sidebar .sidebar-body .nav .nav-item .nav-link').css('color', '#ffffff');
//         }
//     });
//   }

if (window.global === undefined) {
  window.global = window;
}

window.open_parent_url = function(url) {
  window.parent.location.href = url;
};
